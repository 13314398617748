






































import { createComponent, reactive, ref } from '@vue/composition-api'
import { User, searchUsersByPhone } from '@/models/user'

export default createComponent({
  setup() {
    const partnerNames = ref([])
    const isShowDisplayDialog = reactive({
      show: false
    })

    const receivingTelephoneMethod = async (
      phoneNumber: string,
      context: any
    ) => {
      partnerNames.value = []
      // 2023-07-13 受電時のUser情報取得
      await getNamesByPhoneNumber(phoneNumber, context).then((result: any) => {
        if (result) {
          partnerNames.value = result.map(
            (user: User) => `${user.last_name}${user.first_name}`
          )
        } else {
          partnerNames.value = []
        }
      })
      isShowDisplayDialog.show = true
      setTimeout(() => {
        isShowDisplayDialog.show = false
      }, 10000)
    }
    const getNamesByPhoneNumber = async (phoneNumber: string, context: any) => {
      try {
        const result: User[] = await searchUsersByPhone(
          context.root.$axios,
          phoneNumber
        )
        if (result.length > 0) {
          return result
        } else {
          return null
        }
      } catch (error) {
        console.log(error)
      }
    }

    // 相手先名表示/非表示
    const displayPartnerNames = (isDisplay: boolean) => {
      isShowDisplayDialog.show = isDisplay
    }

    return {
      isShowDisplayDialog,
      partnerNames,
      receivingTelephoneMethod,
      displayPartnerNames
    }
  },
  auth: false
})
